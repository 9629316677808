var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('h4',[_vm._v("Pesquisar Solicitações")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","loading":_vm.searchLoading},model:{value:(_vm.searchContent),callback:function ($$v) {_vm.searchContent=$$v},expression:"searchContent"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loadingList,"headers":_vm.columns,"items":_vm.filteredSolicitationList,"footer-props":{ 'items-per-page-options': [5, 8, -1] },"locale":"pt"},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.interacoes(item.solicitationId)}}},on),[_vm._v(" mdi-format-list-numbered ")])]}}],null,true)},[_c('span',[_vm._v("Interações")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.actionType)?_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.actionSolicitationSync(item.id)}}},on),[_vm._v(" mdi-link-variant-plus ")]):_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.actionSolicitationUnsync(item.id)}}},on),[_vm._v(" mdi-link-variant-remove ")])]}}],null,true)},[(_vm.actionType)?_c('span',[_vm._v("Vincular")]):_c('span',[_vm._v("Desvincular")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }