<template>
  <v-container>
    <v-card outlined>
      <v-card-title>
        <h4>Pesquisar Solicitações</h4>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchContent"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
          :loading="searchLoading"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loadingList"
        :headers="columns"
        :items="filteredSolicitationList"
        :footer-props="{ 'items-per-page-options': [5, 8, -1] }"
        locale="pt"
      >
        <template v-slot:item.icon="{ item }">
          <v-icon>{{ item.icon }}</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="interacoes(item.solicitationId)"
              >
                mdi-format-list-numbered
              </v-icon>
            </template>
            <span>Interações</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="actionType"
                small
                v-on="on"
                @click="actionSolicitationSync(item.id)"
              >
                mdi-link-variant-plus
              </v-icon>
              <v-icon
                v-else
                small
                v-on="on"
                @click="actionSolicitationUnsync(item.id)"
              >
                mdi-link-variant-remove
              </v-icon>
            </template>
            <span v-if="actionType">Vincular</span>
            <span v-else>Desvincular</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  props: ["showLinkComponent", "type", "selectedEmailId"],

  data() {
    return {
      dialog: false,
      search: "",
      loadingList: true,
      searchLoading: false,
      searchContent: "",
      columns: [
        { text: "ID", value: "friendly_id" },
        { text: "Criado por", value: "user" },
        { text: "Solicitante", value: "requester.nome" },
        { text: "Cadastro", value: "created_at_format" },
        { text: "Tipo de Solicitação", value: "description" },
        { text: "", align: "end", value: "actions", sortable: false }
      ],
      solicitationList: [],
      solicitationEmailList: "",
      actionType: true
    };
  },
  async mounted() {
    await this.loadOpenedSolicitationList({
      page: 1,
      per_page: -1,
      search: '',
      search_column: '',
      sort_by: '',
      sort_order: '',
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });


    if (this.type == "sync") {
      // this.listSolicitations(this.openedSolicitationList.data);

      this.actionType = true;
    } else {
      await this.loadSyncedSolicitation({
        email_id: this.selectedEmailId
      }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });

      // this.openedSolicitationList.data = [...this.syncedSolicitation.data];
      //
      // let e = this.syncedSolicitation.data;
      // let solicitation = {
      //   solicitationId: e.id,
      //   friendlyId: e.friendly_id,
      //   protocol: e.values.protocol_number?.value,
      //   user: e.user,
      //   created_at_format: e.created_at_format,
      //   solicitationTypeName: e.description
      // };
      // this.solicitationList.push(solicitation);
      this.actionType = false;
    }
    this.loadingList = false;
  },

  computed: {
    ...mapState("emailSync", [
      "openedSolicitationList",
      "syncedSolicitation",
      "unsyncEmail",
      "syncEmail",
      "searchResult"
    ]),

    filteredSolicitationList() {
      if (!this.searchContent) {
        return [...this.openedSolicitationList.data];
      }

      return this.openedSolicitationList.data.filter(item => {
        return (
          String(item.friendly_id).includes(this.searchContent) ||
          String(item.user).toLowerCase().includes(this.searchContent.toLowerCase()) ||
          String(item.requester?.nome).toLowerCase().includes(this.searchContent.toLowerCase()) ||
          String(item.created_at_format).toLowerCase().includes(this.searchContent.toLowerCase()) ||
          String(item.description).toLowerCase().includes(this.searchContent.toLowerCase())
        );
      });
    }
  },
  methods: {
    ...mapActions("emailSync", [
      "loadOpenedSolicitationList",
      "loadSyncedSolicitation",
      "loadSearchSolicitationContent",
      "unsyncEmailAction",
      "syncEmailAction"
    ]),

    interacoes(solicitationId) {
      let routeData = this.$router.resolve({
        name: "interactions",
        params: { id: solicitationId }
      });
      window.open(routeData.href, "_blank");
    },

    async actionSolicitationSync(solicitationId) {
      let syncEmailAction = {
        email_id: this.selectedEmailId,
        solicitation_id: solicitationId
      };
      await this.syncEmailAction(syncEmailAction).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      if (this.syncEmail.success) {
        this.$swal({
          icon: "success",
          title: this.syncEmail.message,
          showCancelButton: false,
          showLoaderOnConfirm: true,
          confirmButtonText: "Continuar",
          confirmButtonColor: "#3085d6"
        }).then(result => {
          if (result.value) {
            this.$router.go();
          }
        });
      } else {
        this.$swal("Oops ...", this.provider_save.message, "warning");
      }
    },

    async actionSolicitationUnsync(solicitationId) {
      await this.$swal({
        icon: "warning",
        text: `Tem certeza que deseja desvincular o e-mail desta solicitação?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#445E93",
        confirmButtonText: "Sim, desvincular!"
      }).then(async result => {
        if (result.value) {
          let unsync = {
            email_id: this.selectedEmailId,
            solicitation_id: solicitationId
          };
          await this.unsyncEmailAction(unsync).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });
          if (this.unsyncEmail.success) {
            this.$swal({
              icon: "success",
              title: this.unsyncEmail.message,
              showCancelButton: false,
              showLoaderOnConfirm: true,
              confirmButtonText: "Continuar",
              confirmButtonColor: "#3085d6"
            }).then(result => {
              if (result.value) {
                this.$router.go();
              }
            });
          }
        }
      });
    }
  }
};
</script>
