<template>
  <v-card>
    <v-card-title class="primary white--text">
      Detalhes do E-mail
      <v-spacer></v-spacer>
      <button class="px-4">
        <v-icon
          class="white--text"
          v-if="!showLinkComponent"
          @click="displaySolicitationSyncListComponent(emailContent.id)"
          >{{ type === 'sync' ? syncIcon : syncedIcon }}</v-icon>
      </button>
      <button>
        <v-icon @click="closeBox" class="white--text">mdi-close</v-icon>
      </button>
    </v-card-title>
    <SolicitationSyncList
      v-if="showLinkComponent"
      :type="type"
      :selectedEmailId="emailContent.id"
    />
    <v-card-text>
      <v-row>
        <v-col>
          <span class="mr-4">De</span>
          <v-chip pill> {{ emailContent.from }} </v-chip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <span>{{ emailContent.subject }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col v-if="emailContent.type_body === 'text'">
          <v-textarea
            label="Conteúdo do e-mail"
            solo
            flat
            hide-details
            auto-grow
            v-model="emailContent.body"
            readonly
          ></v-textarea>
        </v-col>
        <v-col v-else>
          <ShadowHtml :key="emailContent.id" :content="emailContent.body" ></ShadowHtml>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import SolicitationSyncList from "./SolicitationSyncList.vue";
import ShadowHtml from "@/components/interaction/shadowHtmlComponent.vue";
export default {
  props: ["emailContent", "showIcon", "type"],
  components: {
    ShadowHtml,
    SolicitationSyncList
  },
  data() {
    return {
      syncIcon: "mdi-link-variant-plus",
      syncedIcon: "mdi-link-variant-remove",
      menu: false,
      showLinkComponent: false,
      emailData: {}
    };
  },

  methods: {
    displaySolicitationSyncListComponent() {
      this.showLinkComponent = true;
    },

    closeBox() {
      this.$emit("closeBox", false);
      this.showLinkComponent = false;
    }
  }
};
</script>
