<template>
  <v-container class="mt-0 pt-0">
    <v-toolbar flat dense>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab
          v-for="tabName in tabNames"
          :key="tabName"
          @change="showIndex(tabName)"
        >
          {{ tabName }}
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
    <v-tabs-items v-model="tab" v-if="!loading">
      <v-tab-item v-for="tabOption in tabNames" :key="tabOption">
        <EmailSyncList
          :type="emailType"
          ref="refEmailSyncList"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

import EmailSyncList from "./EmailSyncList.vue";

export default {
  components: {
    EmailSyncList
  },
  data() {
    return {
      tabNames: ["Não vinculados", "Já Vinculados"],
      tab: null,
      loading: true,
      emailList: "",
      emailType: "sync",
    };
  },

  async mounted() {
    this.loading = false;
  },

  methods: {
    showIndex(tabName) {
      if (tabName === "Não vinculados") {
        this.emailType = "sync";
      } else {
        this.emailType = "synced";
      }
    },
    async updateEmailSyncList() {
      await this.changeType('');
      await this.changeType("sync"); // força watch
      this.tab = 0;
    },
    async changeType(type) {
      this.emailType = type;
    }
  }
};
</script>
