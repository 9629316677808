<template>
  <v-container class="mt-0 pt-0">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisar"
        single-line
        @change="searchEmail"
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="loadingItems"
      :headers="columns"
      :items="filteredData"
      :items-per-page="perPage"
      :page.sync="currentPage"
      :server-items-length="totalItems"
      :search="search"
      @update:page="onPageChange"
      @update:items-per-page="onItemsPerPageChange"
      @update:sort-by="onSortChange"
      @update:sort-desc="onSortDescChange"
      locale="pt"
    >
      <template v-slot:item.actions="{item}">
        <v-tooltip center>
          <template v-slot:activator="{ on }">
            <v-icon class="mr-4" small @click="openEmailBox(item)"
            >mdi-email-search-outline</v-icon>
          </template>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog scrollable v-model="dialog" max-width="60%">
      <EmailSyncDetail
        @closeBox="closeBox($event)"
        :emailContent="selectedEmailContent"
        :type="type"
        v-if="showDetail"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import EmailSyncDetail from "./EmailSyncDetail";
import { mapActions, mapState } from "vuex";
export default {
  props: ["type"],
  components: {
    EmailSyncDetail
  },
  data: () => ({
    syncIcon: "mdi-link-variant-plus",
    syncedIcon: "mdi-link-variant-remove",
    showIcon: "",
    selectedEmailContent: "",
    showDetail: true,
    loadingItems: true,
    dialog: false,
    emailList: [],
    sortBy: "",
    sortOrder: "",
    search: "",
    columns: [
      { text: "Assunto", value: "subject" },
      { text: "E-mail", value: "from" },
      { text: "Data recebido", value: "formatted_date" },
      { text: "", align: "end", value: "actions", sortable: false }
    ],
    currentPage: 1,
    perPage: 10,
    totalItems: 0,
  }),
  async mounted() {
    await this.fetchEmails();
  },
  computed: {
    ...mapState("emailSync", ["syncEmailList", "syncedEmailList"]),
    filteredData() {
      return this.emailList.filter(value => {
        return (this.search || value.subject.toLowerCase().includes(this.search.toLowerCase())) &&
          (this.search || value.from.toLowerCase().includes(this.search.toLowerCase())) &&
          (this.search || value.formatted_date.toLowerCase().includes(this.search.toLowerCase()))
      });
    }
  },
  methods: {
    ...mapActions("emailSync", ["loadEmailList", "loadSyncedEmailList"]),

    async fetchEmails() {
      this.loadingItems = true;
      this.emailList = [];
      const filter = {
        page: this.currentPage,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        search: this.search
      };
      if (this.type === "sync") {
      await this.loadEmailList(filter);
      this.emailList = this.syncEmailList.data;
      this.totalItems = this.syncEmailList.total;
      } else {
        await this.loadSyncedEmailList(filter);
        this.emailList = this.syncedEmailList.data;
        this.totalItems = this.syncedEmailList.total;
      }
      this.loadingItems = false;
    },

    searchEmail() {
      this.currentPage = 1;
      this.perPage = 10;
      this.sortBy = "";
      this.sortOrder = "";
      this.fetchEmails();
    },

    onSortChange(sortBy) {
      this.sortBy = sortBy[0] === 'formatted_date' ? 'date' : sortBy[0] ;
    },

    onSortDescChange(sortDesc) {
      this.sortOrder = sortDesc[0] === true ? 'desc' : 'asc';
      this.fetchEmails();
    },

    onPageChange(newPage) {
      this.currentPage = newPage;
      this.fetchEmails();
    },

    onItemsPerPageChange(newItemsPerPage) {
      this.perPage = newItemsPerPage;
      this.fetchEmails();
    },

    openEmailBox(email) {
      this.dialog = true;
      this.selectedEmailContent = email;
    },
    closeBox(show) {
      this.dialog = show;
    },
  },

  watch: {
    type: {
      deep: true,
      handler(newType) {
        if (newType != '') {
          this.currentPage = 1;
          this.perPage = 10;
          this.sortBy = "";
          this.sortOrder = "";
          this.search = "";
          this.fetchEmails();
        }
      }
    }
  }
};
</script>
