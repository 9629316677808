<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>
              <v-icon class="mr-3" color="primary">mdi-email-sync-outline </v-icon>
              Vincular e-mails
            </h3>
            <v-spacer></v-spacer>
            <v-btn
              class="ml-2"
              color="primary"
              @click="updateEmails"
              :loading="updatingEmails"
              :disabled="updatingEmails"
            >
              <v-icon>mdi-envelope</v-icon>
              Atualizar e-mails
            </v-btn>
          </v-card-title>
          <Tab ref="tabComponent" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "../../axios.config.js";

import Tab from "../../components/emailSync/Tab.vue";
import Swal from "sweetalert2";
export default {
  components: {
    Tab
  },
  data() {
    return {
      search: "",
      loading: true,
      updatingEmails: false
    };
  },
  methods: {
    async updateEmails() {
      this.updatingEmails = true;
      try {
        const { data } = await axios.get("/email/update")
        await Swal.fire({
          title: "Sucesso!",
          html: data.message,
          icon: "success"
        });
        await this.$refs.tabComponent.updateEmailSyncList();
      } catch (e) {
        await this.$swal(
          e.response.data.message,
          '',
          "error"
        );
      }
      this.updatingEmails = false;
    }
  }
};
</script>
